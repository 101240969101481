@font-face {
  font-family: "Montserrat";
  src: url("../../../fonts/Montserrat/Montserrat Regular 400.ttf") format('truetype');
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../../fonts/Montserrat/Montserrat SemiBold 600.ttf") format('truetype');
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../../fonts/Montserrat/Montserrat Bold 700.ttf") format('truetype');
}
