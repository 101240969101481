.react-select__indicator-separator {
  display: none;
}
.react-select__indicator-separator:disabled {
  display: none;
}

.react-select__control {
  background-color: whitesmoke !important;
  border: 0.5px solid #f3f3f3 !important;
  border-radius: 0 !important;
  font-size: 15px !important;
  padding-top: 4px;
  padding-bottom: 4px;
}