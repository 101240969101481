.btn .sweet-loading {
  position: relative;
  text-align: center;
  margin: 0;
  top: 0;
  left: 0;

  > div {
    height: 20px;
    width: 20px;
  }
}