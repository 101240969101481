.ReactTable {
  .rt-tr-group{
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-bottom: 10px;
  }
  .rt-table, .rt-tbody, .rt-th, .rt-td {
    overflow: visible;
    // padding: 10px;
  }

  .rt-td{
    // align-items: center;
    // display: flex;
    // overflow: visible !important;
  }

  .rt-thead{
    margin-bottom: 20px;
  }

  .pagination-bottom{
    .dropdown-toggle{
      border-width: 2px; 
    }
    .dropdown-menu{
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
    }
  }
}


.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all .3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(256, 256, 256, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all .3s cubic-bezier(.25, .46, .45, .94)
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%)
}


.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all .3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(256, 256, 256, 0.5);
  color: black;
}

.col-product-name {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}