@import "_fonts.scss";
@import "_color.scss";
@import "./_helper.scss";
@import "./_modal.scss";
@import "./_select.scss";

html {
  background-color: #ffff !important;
}

body {
  font-family: "Montserrat";
  font-size: 17px;
}

.btn-link {
  color: $primary;
}

textarea {
  resize: none;
  color: $black !important;
}

.w-cursor {
  cursor: pointer !important;
}

.no-cursor {
  cursor: default !important;
}

.font-weight-bold {
  font-family: "Montserrat-Bold";
}

.large-title {
  font-family: "Montserrat-Bold";
  font-size: 32px;
}

.title1 {
  font-family: "Montserrat-Bold";
  font-size: 28px;
}

.title2 {
  font-family: "Montserrat-Bold";
  font-size: 22px;
}

.title3 {
  font-family: "Montserrat-Bold";
  font-size: 20px;
}

.sort-label,
.filter-label,
.bulk-label {
  font-family: "Montserrat-Bold";
  font-size: 14px;
}

.headline {
  font-family: "Montserrat-SemiBold";
  font-size: 17px;
}

.placeholder {
  font-size: 16px;
  font-family: "Montserrat";
}

.sidebar {
  color: $black;
}

.dropdown-item {
  color: $black !important;
  &:active {
    color: white !important;
  }
}

.dropdown-menu {
  border-color: $light !important;
}

.error-container {
  color: #cf1827;
  background-color: #fae8e9;
  border-radius: 15px;
  font-size: 12px;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.input {
  border-color: $border-stroke-color !important;
}

.text-muted {
  color: $border-stroke-color !important;
}

.input-group {
  border: solid;
  border-width: 0.5px !important;
  input {
    border: none !important;
  }

  > a {
    border: none !important;
  }

  input:focus {
    border-color: $border-stroke-color !important;
  }
}

.form-control {
  border-color: $border-stroke-color !important;
}

input {
  color: black !important;
  // &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  //   color: $textLightGray;
  //   opacity: 1; /* Firefox */
  // }

  // &:-ms-input-placeholder { /* Internet Explorer 10-11 */
  //   color: $textLightGray;
  // }

  // &::-ms-input-placeholder { /* Microsoft Edge */
  //   color: $textLightGray;
  // }
}

.custom-dropdown {
  &:after {
    display: none;
  }
}

.list-btn {
  font-size: 18px;
  font-family: "Montserrat-Bold";
  text-transform: uppercase;
}

.text-black {
  color: $black;
}

.breadcrumb-item {
  font-size: 16px;
  font-family: "Montserrat";
  color: #8f8f8f;
}

.list-recordsFound {
  font-size: 14px;
  color: #8f8f8f;
}

.td-action {
  overflow: unset !important;

  .btn-group {
    padding-right: 15px;
    .btn {
      padding-top: 6px;
      text-transform: uppercase;
    }
  }
}

.required {
  color: $primary !important;
}

.custom-modal-dialog-centered {
  box-shadow: none !important;
}

.pagination-bottom {

  .btn-outline-primary {
    width: 60px;
    height: 30px;
  }
}

.btn-outline-primary {
  border-width: 2px;
  font-family: "Montserrat-Bold";
}

.rt-th {
  font-family: "Montserrat-SemiBold";
  font-size: 17px;
}

.rt-td, .rt-th {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.basic-multi-select {
  .select__control {
    height: 45px;
    border-color: #8f8f8f;
    border-radius: 8px;
  }
}
.react-multiselect {
  &__control {
    height: 45px;
    border-color: #8f8f8f;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 8px;
  }
}

.custom-dropdown {
  > button {
    padding: 2px 15px !important;
    background-color: white;
    color: $secondary;
    border: 2px solid $primary;
    font-weight: bold;
    color: $primary;
    &:hover {
      background-color: $primary;
      border: 2px solid $primary;
    }
  }
}

.custom-dropdown-menu {
  border-radius: 12px !important;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  button,
  .dropdown-item {
    font-weight: bold;
    color: #4a4a4a !important;
    text-transform: uppercase;
    text-align: left;
  }
}

.custom-badge {
  padding: 10px 15px !important;
  border-radius: 50px;
  text-transform: capitalize;
}

.standard {
  background-color: $standard !important;
  color: black;
}

.rush {
  background-color: $rush !important;
}

.processing,
.preparing {
  background-color: $pending !important;
  color: black;
}

.to-ship,
.shipped {
  background-color: $blue !important;
  color: white;
}

.delivered,
.received {
  background-color: $green !important;
  color: white;
}

.cancelled,
.dispute {
  background-color: $red !important;
  color: white;
}

.custom-textarea {
  border-radius: 5px;
  height: 40px;
  padding: 5px 10px;
  border-color: $border-stroke-color;
}

.dropdown-menu{
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
}

.custom-select__multi-value{
  background-color: #FAE8E9 !important;
  border-radius: 5px;
  font-weight: bold;
}

.version-container {
  position: relative;
  bottom: 0;
  text-align: center;
  width: 100%;
}
