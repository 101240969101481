.bottom-modal {
  .modal-header {
    .modal-title {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;

      .title {
        font-weight: bold;
        // color: var(--primary);
        font-size: 18px;
      }
    }
  }

}