//variables for social
$social-facebook: #3b5998;
$social-twitter: #55acee;
$social-pinterest: #cc2127;
$social-google: #dd4b39;
$social-linkedin: #0976b4;
$social-dribbble: #ea4c89;
$social-github: #333333;
$social-youtube: #e52d27;
$social-instagram: #125688;
$social-reddit: #ff4500;
$social-tumblr: #35465c;
$social-behance: #1769ff;

$transparent-bg: rgba(200, 200, 200, 0.2) !default;

$heading-color: #464855;
$content-color: #6B6F82;
$background-light-grey: #e8e7e3 !default;
$background-lighter-grey: #f0efeb !default;
$font-background-light-grey: #9c9b99 !default;
$font-hover-background-light-grey: #5e5e5c !default;

$primary: #8D222C;
$success: #0cc27e;
$info: #1cbcd8;
$warning: #ff8d60;
$danger: #ff586b;
$secondary: #868e96;
$light: #f8f9fa;
$dark: #343a40;
$black: #1E090C;
$body-bg: #f5f7fa;
$white: #ffffff;
$dirtyWhiteDarken: #d9d9d9;

$maroon: #8D222C;
$error: #C20000;
$errContainer: #F9E6E6;
$darkGrayTextColor: #111111;
$textColor: #4A4A4A;
$border-input-color: #E7E7E7;
$grayTextColor: #414141;
$textLightGray: #888888;
// $border-input-color: #E7E7E7;
$inputLightBg: #F5F5F5;
$buttonLabel: #FCFCFC;
$border-stroke-color: #8F8F8F;

$hover-button-light: #4A4A4A;
$standard: #FAE8E9;
$pending: #FFDA83;
$rush: #A44E56;
$blue: #0A89E4;
$green: #41924B;
$red: #CF1827;